export function handleMenuClick(e: any) {
	e.currentTarget.classList.toggle("Active");
	document.querySelector(".FloatingMenuBlock")?.classList.toggle("Active");
}

export function handleRequestSubmit(e: any) {
	const button = e.currentTarget;
	const form = document.getElementById('message_form');
	// @ts-ignore
	if (form && form.checkValidity()) {
		e.preventDefault();
		button.disabled = true;
		// @ts-ignore
		const formData = new FormData(form);
		const name = formData.get('name');
		const email = formData.get('email');
		const phone = formData.get('phone');
		const message = formData.get('message');

		const url = '/api';
		const xhr = new XMLHttpRequest();
		xhr.open("POST", url, true);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onload =  () => {
			if (xhr.status >= 200 && xhr.status < 400) {
				// @ts-ignore
				form.reset();
				alert('Request sent successfully');
			} else {
				alert('Network error');
			}

			button.disabled = false;
		};
		xhr.onerror =  () => {
			alert('Network error');
			button.disabled = false;
		};
		xhr.send(JSON.stringify({name, email, phone, message}));
	} else {
		button.disabled = false;
	}
}

export function handleContactUsClick () {
	let targetElement = document.querySelector("#contact-us");

	if (targetElement) {
		let offset = 100;
		//@ts-ignore
		let targetPosition = targetElement.offsetTop - offset;

		window.scrollTo({
			top: targetPosition,
			behavior: 'smooth'
		});
	}
}

export function handleHrefClick(this: any, e: any) {
	e.preventDefault();

	let targetId = this.getAttribute('href');
	let targetElement = document.querySelector(targetId);

	if (targetElement) {
		let offset = 100;
		let targetPosition = targetElement.offsetTop - offset;

		window.scrollTo({
			top: targetPosition,
			behavior: 'smooth'
		});
	}

	document.querySelector(".Sandwich")?.classList.remove("Active");
	document.querySelector(".FloatingMenuBlock")?.classList.remove("Active");
}

export function handlePlanItemCLick (this: any, e: any) {
	document.querySelectorAll('.PlanItem.Active').forEach(anchor => {
		anchor.classList.remove("Active");
	});

	this.classList.add("Active");
}
