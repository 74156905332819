"use client"
import React, {useState, useEffect} from "react";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

let resizeTimeout: any;

function ProductsSlider() {
	const [windowSize, setWindowSize] = useState(0);

	useEffect(() => {
		const resizeScreen = () : any => {
			clearTimeout(resizeTimeout);
			resizeTimeout = setTimeout(()  => {
				setWindowSize(window.innerWidth);
			}, 500)
		};

		window.addEventListener('resize', resizeScreen);

		return () => {
			window.removeEventListener('resize', resizeScreen);
		};
	}, []);

	useEffect(() => {
		setWindowSize(window.innerWidth);
	}, []);

	if (windowSize < 744) {
		return (
			<Splide hasTrack={ false } options={{
				arrows: false
			}}>
				<SplideTrack className='ProductSlider'>
					<SplideSlide className='Content'>
						<div className="Image">
							<img src="/img/social-dashboard.png" alt="transition" />
						</div>
						<div className="Text">
							<span className="TypographyTitle4 ColorDark">AgilePulse: Innovative Market Entry</span>
							<span className="TypographyBody32 ColorDark">
                      AgilePulse - your reliable companion for swift and successful market entry. Our innovative toolkit and
                      technologies enable you to present your product or service most effectively, highlighting its unique
                      advantages. With AgilePulse, your business is ready for a successful launch and dynamic growth in today&apos;s
                      market.
                    </span>
						</div>
					</SplideSlide>
					<SplideSlide className='Content'>
						<div className="Text">
							<span className="TypographyTitle4 ColorDark">InnovaTech Catalyst</span>
							<span className="TypographyBody32 ColorDark">
                      InnovaTech Catalyst is your go-to solution for accelerating product development. Our cutting-edge technology
                      and expertise empower you to swiftly transform ideas into fully realized products, ensuring you stay ahead
                      in the competitive market. With InnovaTech Catalyst, innovation knows no bounds, and success is within reach.
                    </span>
						</div>
						<div className="Image LaptopPhoneGroup">
							<img className="LaptopImg" src="/img/laptop_phone.png" alt="laptop" />
						</div>
					</SplideSlide>
				</SplideTrack>
			</Splide>
		)
	}

	return (
		<div id="ProductsSplide" className="spl">
			<div className="spl__track ">
				<div className="spl__list  ProductSlider">
					<div className="spl__slide  Content">
						<div className="Image">
							<img src="/img/social-dashboard.png" alt="transition" />
						</div>
						<div className="Text">
							<span className="TypographyTitle4 ColorDark">AgilePulse: Innovative Market Entry</span>
							<span className="TypographyBody32 ColorDark">
                      AgilePulse - your reliable companion for swift and successful market entry. Our innovative toolkit and
                      technologies enable you to present your product or service most effectively, highlighting its unique
                      advantages. With AgilePulse, your business is ready for a successful launch and dynamic growth in today&apos;s
                      market.
                    </span>
						</div>
					</div>
					<div className="spl__slide  Content">
						<div className="Text">
							<span className="TypographyTitle4 ColorDark">InnovaTech Catalyst</span>
							<span className="TypographyBody32 ColorDark">
                      InnovaTech Catalyst is your go-to solution for accelerating product development. Our cutting-edge technology
                      and expertise empower you to swiftly transform ideas into fully realized products, ensuring you stay ahead
                      in the competitive market. With InnovaTech Catalyst, innovation knows no bounds, and success is within reach.
                    </span>
						</div>
						<div className="Image LaptopPhoneGroup">
							<img className="LaptopImg" src="/img/laptop_phone.png" alt="laptop" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProductsSlider;
