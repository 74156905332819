"use client"
import React from "react";
import { handleContactUsClick } from "../../utils/clientHandlers";


function ContactUsButton() {
	return (
		<button className="AppButton BackgroundColorOrange TypographyBody31 ColorWhite" onClick={handleContactUsClick}>
			Contact us
		</button>
	);
}

export default ContactUsButton;
