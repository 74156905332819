"use client"
import React from "react";
import { handleRequestSubmit } from "../../utils/clientHandlers";

function SubmitButton() {
	return (
		<button
			className="AppButton BackgroundColorSilver TypographyBody31 ColorDark"
			onClick={handleRequestSubmit}
		>
			Submit
		</button>
	);
}

export default SubmitButton;
